import { ReactNode } from 'react';
import styled from 'styled-components';

export const styledPayStatus = (Component: ReactNode) => styled(Component)`
  display: flex;
  flex-direction: column;
  height: 100vh;

  > .body {
    flex: 1;

    .layout {
      height: 100%;
      display: flex;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .message {
        color: var(--color-white);
        display: flex;
        line-height: 1.5;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 200px;
        background-color: linear-gradient(to left, var(--color-accent), var(--color-primary));
        background-color: var(--color-dark-grey);
        border-radius: 3px;

        span:nth-child(1) {
          font-size: 1.5rem;
          font-weight: 700;
        }
        span:nth-child(2), span:nth-child(3) {
          opacity: 0.7;
        }

        a {
          margin-top: 30px;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
`