import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { styledPayStatus } from '../styled/styledPayStatus';

const ContactSuccess = ({ className }) => (
  <Layout className={className}>
    <SEO title="Thank you" />
    <div className="message">
      <span>Thank you</span>
      <span>We've received your details</span>
      <Link
        to="/"
        style={{
        }}
      >
        &#8592; Back to our site
      </Link>
    </div>
  </Layout>
)

export default styledPayStatus(ContactSuccess);
